import { Action } from 'redux-actions';
import {
    createRoutineCreator,
    defaultRoutineStages,
} from 'redux-saga-routines';

import {
    genApiSchemaState,
    setSingleState,
    RESET_APP_ACTION,
} from 'src/utils/redux';

import { wrapWithNamespace } from '../constants';
import { Driver, RetrieveDriversState } from '../types';

const createRetrieveDriverRoutine = createRoutineCreator([
    ...defaultRoutineStages,
    'UPDATE',
]);

export const retrieveDriversAction = createRetrieveDriverRoutine(
    wrapWithNamespace('retrieve'),
);

export const defaultDriver: Driver = {
    firstName: '',
    lastName: '',
    email: '',
    addedInSci: true,
    hirer: true,
    document: {
        number: '',
        attributes: {},
        documentType: {
            issuer: '',
            issuerName: '',
            state: '',
            name: 'Driver license',
        },
    },
};

const initialState: RetrieveDriversState = genApiSchemaState<Driver[]>({
    loading: false,
    data: [defaultDriver],
});

const retrieveDriversReducer = (
    state = initialState,
    { type, payload }: Action<Driver[]>,
): RetrieveDriversState => {
    const handler = HANDLERS[type as any];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: RetrieveDriversState, payload: Driver[]) => ({
    ...state,
    loading: false,
    data: payload,
    error: {},
});

const handleError = (_state: RetrieveDriversState, payload: any) => ({
    ...initialState,
    loading: false,
    error: payload,
});

const handleUpdate = (state: RetrieveDriversState, payload: Driver[]) => {
    const drivers = state.data?.map(
        (driver) =>
            payload.find(
                (payloadDriver) =>
                    payloadDriver.id?.profileId === driver.id?.profileId,
            ) || driver,
    );
    return {
        ...state,
        loading: false,
        data: drivers,
        error: {},
    };
};

const HANDLERS = {
    [retrieveDriversAction.TRIGGER]: handleTrigger,
    [retrieveDriversAction.SUCCESS]: handleSuccess,
    [retrieveDriversAction.FAILURE]: handleError,
    [retrieveDriversAction.UPDATE]: handleUpdate,
    [RESET_APP_ACTION]: () => initialState,
};

export default retrieveDriversReducer;
