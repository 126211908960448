import { defineMessages } from 'react-intl';

const header = defineMessages({
    thanksFor: {
        id: `registerCompletion.content.thanksFor`,
        defaultMessage: 'Thank you. Your registration is now complete.',
    },
    LookOut: {
        id: `registerCompletion.content.LookOut`,
        defaultMessage:
            'Look out for some other emails from us in the lead-up to your arrival.' +
            'They are packed full of handy hints, tips, and travel tools to get you prepared for the adventure ahead.',
    },
    welcoming: {
        id: `registerCompletion.content.welcoming`,
        defaultMessage: 'We look forward to welcoming you soon!',
    },
});

export const title = defineMessages({
    title: {
        id: `sciPage.title`,
        defaultMessage: 'THL Self Check In',
    },
});

export default header;
