import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { B2C } from 'cosmos-components';

import { SciPage } from 'src/components/shared/SciPage';
import {
    setCarouselDotsCurrentPage,
    setCarouselPageIsCompletedAction,
} from 'src/components/shared/SciPage/SciPage.duck';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { sciContentKeys } from 'src/constants';
import { CaBrandNames, UsBrandNames } from 'src/enums';
import { useSciContent } from 'src/hooks/useSciContent';

import * as S from '../shared/products.style';
import { ProductsForm } from '../shared/products.form';

import msg from './messages';
import { MODULE_PREFIX } from './vehicleProtections.constants';

export const componentVehicleProtections = `${MODULE_PREFIX}-component`;

export const VehicleProtectionsComponent: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const brandName = useSelector(sciBrandNameSelector);
    const isRoadBear = brandName === UsBrandNames.roadBear;
    const isElmonte = brandName === UsBrandNames.elmonte;
    const isCanadream = brandName === CaBrandNames.canadream;

    const isUS = isRoadBear || isElmonte;

    const title = intl.formatMessage(msg.formHeaderItems.title);
    const titleUs = intl.formatMessage(msg.formHeaderItems.titleUs);
    const titleCa = intl.formatMessage(msg.formHeaderItems.titleCa);

    useEffect(() => {
        dispatch(setCarouselDotsCurrentPage(SciPagesEnum.VehicleProtections));
    }, [dispatch]);

    const {
        data: sciAccidentLiabilityPageLabel,
        isLoading: isSciAccidentLiabilityPageLoading,
    } = useSciContent(sciContentKeys.sciAccidentLiabilityPage);

    return (
        <SciPage data-test-id={componentVehicleProtections}>
            <S.Container>
                <S.Header>
                    <S.Title>
                        {isUS ? titleUs : isCanadream ? titleCa : title}
                    </S.Title>
                    <S.Text>
                        <B2C.LoaderContainer
                            loading={isSciAccidentLiabilityPageLoading}
                            size={'small'}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: sciAccidentLiabilityPageLabel ?? '',
                                }}
                            ></span>
                        </B2C.LoaderContainer>
                    </S.Text>
                </S.Header>
                <ProductsForm
                    category="Insurances"
                    successCallback={() => {
                        dispatch(
                            setCarouselPageIsCompletedAction({
                                page: SciPagesEnum.VehicleProtections,
                            }),
                        );
                    }}
                />
            </S.Container>
        </SciPage>
    );
};
