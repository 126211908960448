import { defineMessages } from 'react-intl';

export const formHeaderItems = defineMessages({
    title: {
        id: 'vehicleProtections.formHeaderItem.title',
        defaultMessage: 'Accident Liability',
    },
    titleCa: {
        id: 'vehicleProtections.formHeaderItem.titleCa',
        defaultMessage: 'Accident Liability & Options',
    },
    titleUs: {
        id: `vehicleProtections.formHeaderItem.titleUs`,
        defaultMessage: 'Insurance and Coverage',
    },
    warningLiability: {
        id: 'vehicleProtections.formHeaderItem.warningLiability',
        defaultMessage:
            // eslint-disable-next-line no-template-curly-in-string
            'You are liable for up to ${liabilityAmount}^ per incident (depending on vehicle)',
    },
    errorHeader: {
        id: 'vehicleProtections.formHeaderItem.errorHeader',
        defaultMessage: `Error`,
    },
    insuranceError: {
        id: 'vehicleProtections.formHeaderItem.insuranceError',
        defaultMessage:
            'Please select at least one accident liability product.',
    },
});

const vehicleProtectionsMessages = {
    formHeaderItems,
};

export default vehicleProtectionsMessages;
