import { createRoutine } from 'redux-saga-routines';

import { AcceptedLocalsType } from 'cosmos-components/dist/i18n';

import { wrapWithModule, RESET_APP_ACTION } from 'src/utils/redux';
import { SciPagesEnum, SciPageState } from './types';
import {
    BrandNames,
    UkBrandNames,
    UsBrandNames,
    CaBrandNames,
} from 'src/enums';
import { SCIDefaultFlowPaths, SCISpecificFlow } from 'src/constants';

const wrapWithNamespace = wrapWithModule('sciPage');

const initialiseCarouselAction = wrapWithNamespace(
    'carouselDots/initialiseCarousel',
);

const setCarouselCurrentPageAction = wrapWithNamespace(
    'carouselDots/setCurrentPage',
);

const setLanguageAction = wrapWithNamespace('carouselDots/setLanguage');

export const setCarouselPageIsCompletedAction = createRoutine(
    wrapWithNamespace('carouselDots/setPageIsCompleted'),
);

const setFlowAsCompletedAction = wrapWithNamespace(
    'carouselDots/setFlowAsCompletedAction',
);

type Types = typeof setCarouselCurrentPageAction;

const getBrandName = () => {
    const lowerCaseUrl = (window?.location?.href ?? '').toLocaleLowerCase();

    if (lowerCaseUrl.includes(UsBrandNames.roadBear)) {
        return BrandNames.roadBear;
    }
    if (lowerCaseUrl.includes(UsBrandNames.elmonte)) {
        return BrandNames.elmonte;
    }
    if (lowerCaseUrl.includes(UkBrandNames.europe)) {
        return BrandNames.europe;
    }
    if (lowerCaseUrl.includes(CaBrandNames.canadream)) {
        return BrandNames.canadream;
    }

    return undefined;
};

const initialState: SciPageState = {
    brandName: getBrandName(),
};

const sciPageReducer = (
    state = initialState,
    { type, payload }: { type: Types; payload: any } = {
        type: '',
        payload: {},
    },
): SciPageState => {
    const handler = HANDLERS[type];

    return handler ? handler(state, payload) : state;
};

export const initialiseCarousel = (payload: {
    orgId: string;
    isRelocation: boolean;
}) => {
    return { type: initialiseCarouselAction, payload };
};

export const setCarouselDotsCurrentPage = (currentPage: SciPagesEnum) => {
    return { type: setCarouselCurrentPageAction, payload: currentPage };
};

export const setLanguage = (language: AcceptedLocalsType | undefined) => {
    return { type: setLanguageAction, payload: { language } };
};

export const setFlowAsCompleted = () => ({
    type: setFlowAsCompletedAction,
});

const handleInitialiseCarousel = (
    state: SciPageState,
    payload: { orgId: string; isRelocation: boolean },
): SciPageState => ({
    ...state,
    carouselDotsState: {
        currentPage: SciPagesEnum.RetrieveSelfCheckInPage,
        items: SCISpecificFlow.reduce(
            (acc, flow) => (!!flow.condition(payload) ? flow.paths : acc),
            SCIDefaultFlowPaths,
        ),
        flowCompleted: false,
    },
});

const handleLanguageAction = (
    state: SciPageState,
    payload: { language: AcceptedLocalsType },
): SciPageState => ({
    ...state,
    language: payload.language,
});

const handleCarouselCurrentPageAction = (
    state: SciPageState,
    payload: SciPagesEnum,
): SciPageState => ({
    ...state,
    carouselDotsState: state.carouselDotsState
        ? {
              ...state.carouselDotsState,
              currentPage: payload,
          }
        : undefined,
});

const handleCarouselPageIsCompletedAction = (
    state: SciPageState,
    payload: SciPagesEnum,
): SciPageState => {
    if (!state?.carouselDotsState) {
        return state;
    }

    const pageIndex = state.carouselDotsState.items.findIndex(
        (item) => item.pageEnum === payload,
    );

    if (pageIndex < 0) {
        return state;
    }

    const items = [...state.carouselDotsState.items];
    items[pageIndex].isCompleted = true;
    return {
        ...state,
        carouselDotsState: {
            ...state.carouselDotsState,
            items: items,
        },
    };
};

const handleSetFlowAsCompletedAction = (state: SciPageState): SciPageState => ({
    ...state,
    carouselDotsState: state.carouselDotsState
        ? {
              ...state.carouselDotsState,
              flowCompleted: true,
          }
        : undefined,
});

const HANDLERS = {
    [initialiseCarouselAction]: handleInitialiseCarousel,
    [setCarouselCurrentPageAction]: handleCarouselCurrentPageAction,
    [setLanguageAction]: handleLanguageAction,
    [setCarouselPageIsCompletedAction.SUCCESS]:
        handleCarouselPageIsCompletedAction,
    [setFlowAsCompletedAction]: handleSetFlowAsCompletedAction,
    [RESET_APP_ACTION]: () => initialState,
};

export default sciPageReducer;
